import * as React from "react"

function SvgArrowRight(props) {
  return (
    <svg height="490pt" viewBox="0 -10 490.667 490" width="490pt" {...props}>
      <path
        fill="#FFF"
        d="M325.332 251H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h309.332c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"
      />
      <path
        fill="#FFF"
        d="M240 336.332c-4.098 0-8.191-1.555-11.309-4.691-6.25-6.25-6.25-16.383 0-22.637l74.028-74.024-74.028-74.027c-6.25-6.25-6.25-16.387 0-22.637 6.254-6.25 16.387-6.25 22.637 0l85.332 85.336c6.25 6.25 6.25 16.383 0 22.633l-85.332 85.332A15.88 15.88 0 01240 336.332zm0 0"
      />
      <path
        fill="#FFF"
        d="M256 469.668c-97.09 0-182.805-58.41-218.41-148.824-3.242-8.192.808-17.492 9.023-20.735 8.192-3.199 17.516.79 20.758 9.047C98.113 387.215 172.161 437.668 256 437.668c111.742 0 202.668-90.926 202.668-202.668S367.742 32.332 256 32.332c-83.84 0-157.887 50.453-188.629 128.512-3.266 8.258-12.566 12.246-20.758 9.047-8.215-3.243-12.265-12.543-9.023-20.735C73.195 58.742 158.91.332 256 .332 385.387.332 490.668 105.613 490.668 235S385.387 469.668 256 469.668zm0 0"
      />
    </svg>
  )
}

export default SvgArrowRight