import * as React from "react"
import { useRef } from "react"
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useAnimation,
  useTransform,
} from "framer-motion"
import "./main.scss"
import { Icon } from "@iconify/react"
import codeIcon from "@iconify/icons-icons8/code-file"
import mailIcon from "@iconify/icons-bytesize/mail"
import { useDispatch } from "react-redux"
import { SiLighthouse } from "react-icons/si"

const duration = 0.5

const variantsbtn = {
  initial: {
    opacity: 0,
    y: 10,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration,
      delay: 0.6,
      type: "tween",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.3, delay: 0.3 },
  },
}

const grabCircle = {
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}

export const ButtonOut = ({
  children,
  href,
  Id,
  color,
  icon,
  action,
  ...props
}) => {
  const controls = useAnimation()
  const constraintsRef = useRef(null)
  const dispatch = useDispatch()

  // const x = useMotionValue(0);
  // const xInput = [0, 200];

  // const y = useTransform(x, latest => latest * 2)
  // const scale = useTransform(x, xInput, [1, 7]);
  // const opacity = useTransform(x, xInput, [0.7, 1]);
  // const left = useTransform(x, xInput, [15, 70]);
  // const backgroundColor = useTransform(x, [0, 700], ["#130a44", "#09474b"])
  // const internal = /^\/(?!\/)/.test(href);

  const buttonAnims = {
    tap: {
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 3,
        mass: 0.2,
        stiffness: 150,
      },
    },
    hover: {
      opacity: 1,
      transition: {
        duration: 1,
        type: "spring",
        damping: 3,
        mass: 0.2,
        stiffness: 150,
      },
    },
  }

  const buttonAnims2 = {
    tap: {
      scale: 0.98,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 3,
        mass: 0.2,
        stiffness: 150,
      },
    },
    hover: {
      scale: 1.06,
      y: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 3,
        mass: 0.2,
        stiffness: 150,
      },
    },
  }

  const buttonAnimsColor = {
    tap: {
      scale: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 3,
        mass: 0.2,
        stiffness: 150,
      },
    },
    hover: {
      backgroundColor: "rgb(85 0 197)",
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
  }

  const buttonAnimsColorGreen = {
    tap: {
      scale: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 3,
        mass: 0.2,
        stiffness: 150,
      },
    },
    hover: {
      backgroundColor: "rgb(49, 154, 115)",
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
  }

  const buttonAnimsArrow = {
    tap: {
      opacity: 1,
      rotate: 0,
      scale: 1,
      x: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 3,
        mass: 0.2,
        stiffness: 150,
      },
    },
    hover: {
      x: 0,
      rotate: 0,
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
  }

  const buttonTapAreaAnims = {
    tap: {
      scale: [0, 2],
      opacity: [0.05, 0.1, 0],
      transition: {
        duration: 0.4,
        type: "tween",
      },
    },
    hover: {
      scale: [0, 2],
      opacity: [0.05, 0.1, 0],
      transition: {
        duration: 0.4,
        type: "tween",
      },
    },
  }

  const shine = {
    initial: { opacity: 0, scale: 1 },
    hover: {
      opacity: 0.35,
      scale: 1.5,
      duration: 0.25,
    },
  }

  function clickmail() {
    dispatch({ type: "FormIsOpen", value: true })
  }

  function clickaudit() {
    dispatch({ type: "AuditIsOpen", value: true })
  }

  return (
    <>
      <AnimatePresence>
        <motion.button
          initial="initial"
          animate="enter"
          exit="exit"
          className="focus:outline-none focus:border-none"
          ref={constraintsRef}
          variants={variantsbtn}
          key={Id}
          onClick={
            (action === "mail" && (() => clickmail())) ||
            (action === "audit" && (() => clickaudit()))
          }
        >
          <motion.div className="relative text-white cursor-pointer" {...props}>
            <motion.div
              whileHover="hover"
              whileTap="tap"
              className="w-full relative gap-5 z-20 h-full flex flex-row items-center justify-center"
            >
              {color === undefined && (
                <motion.div
                  className="gradient-blur-btn pointer-events-none"
                  variants={shine}
                ></motion.div>
              )}
              {color === "pink" && (
                <motion.div
                  className="gradient-blur-btn-green pointer-events-none"
                  variants={shine}
                ></motion.div>
              )}
              <motion.span
                variants={buttonAnims}
                initial={{ opacity: 0.7 }}
                className="flex flex-row gap-5 absolute z-20 text-lg items-center justify-center text-center font-radiance"
              >
                {icon === undefined && (
                  <motion.div
                    initial={{ opacity: 0, x: -25 }}
                    variants={buttonAnimsArrow}
                    className="h-10 arrow_svg "
                  />
                )}
                {icon === "mail" && (
                  <motion.div
                    initial={{ opacity: 0, x: -25 }}
                    variants={buttonAnimsArrow}
                  >
                    <Icon icon={mailIcon} className="h-auto w-8" />
                  </motion.div>
                )}
                {icon === "projects" && (
                  <motion.div
                    initial={{ opacity: 0, x: -25 }}
                    variants={buttonAnimsArrow}
                  >
                    <Icon icon={codeIcon} className="h-8 w-auto" />
                  </motion.div>
                )}
                {icon === "audit" && (
                  <motion.div
                    initial={{ opacity: 0, x: -25 }}
                    variants={buttonAnimsArrow}
                  >
                    <SiLighthouse className="h-8 w-auto" />
                  </motion.div>
                )}
                <motion.div
                  className="select-none"
                  initial={{ x: -22 }}
                  variants={buttonAnimsArrow}
                >
                  {children}
                </motion.div>
              </motion.span>
              <motion.div
                className="absolute pointer-events-none w-full h-full overflow-hidden shadow-lg"
                variants={buttonAnims2}
              >
                <div className="absolute w-full h-full flex items-center justify-center">
                  <motion.div
                    style={{ scale: 0, opacity: 0 }}
                    variants={buttonTapAreaAnims}
                    className="z-10 rounded-full bottom-0 h-40 w-40 bg-black opacity-25"
                  ></motion.div>
                </div>
                {color === undefined && (
                  <>
                    <motion.div className="absolute z-10 overlayAnimate pointer-events-none rounded-lg w-full h-full"></motion.div>
                    <motion.div
                      className="w-full h-full rounded-lg shadow-lg"
                      variants={buttonAnimsColor}
                      initial={{ backgroundColor: "rgb(45 14 107)" }}
                    ></motion.div>
                  </>
                )}
                {color === "pink" && (
                  <>
                    <motion.div className="absolute z-10 overlayAnimatePink pointer-events-none rounded-lg w-full h-full"></motion.div>
                    <motion.div
                      className="w-full h-full rounded-lg shadow-lg"
                      variants={buttonAnimsColorGreen}
                      initial={{ backgroundColor: "rgb(8 121 100)" }}
                    ></motion.div>
                  </>
                )}
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.button>
      </AnimatePresence>
    </>
  )
}
