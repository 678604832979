/* eslint-disable react/prop-types */
import * as React from 'react';
import { motion } from 'framer-motion';


const LinkAnim = ({ children, href }) => {
    return (
        <>
        <a href={href} target="_blank" rel="noopener noreferrer" className="inline-block">
            <motion.div
                initial="enter"
                whileTap={{ scale: 0.95, opacity: 0.6 }}
                whileHover={{ x: 6 }}
                className="p-2 flex flex-row items-center justify-center gap-2"
                transition={{
                    duration: 0.5,
                    x: { type: 'spring', stiffness: 200, damping: 13 },
                }}
            >

                
            {children}
            </motion.div>
            </a>
        </>
    );
};

export default LinkAnim;
