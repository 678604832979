import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO/seo';
import Prez from '../components/home/Prez/prez';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const IndexPage = () => {

  const { t } = useTranslation(['titles']);

  return (
    <>
      <SEO title={t('titles~Home')} />
      <section className="home_gradient">
        {/* <ReportViewer json={jsonReport} /> */}
        <Prez />
      </section>
      {/* <Slideshow />
    <Slideshow /> */}
    </>
  )
}

const lineWrapper = {
  initial: { x: 0 },
  animate: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.15,
    },
  },
}

const line = {
  initial: { y: "-100%" },
  animate: {
    y: 0,
    transition: {
      type: "spring",
      duration: 1.5
    },
  },
}

const lineReverse = {
  initial: { y: "100%" },
  animate: {
    y: 0,
    transition: {
      type: "tween",
      ease: [0.17, 0.67, 0.83, 0.67],
    },
  },
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// const data = useStaticQuery(graphql`
// query {
//   file(relativePath: { eq: "images/default.jpg" }) {
//     childImageSharp {
//       # Specify a fixed image and fragment.
//       # The default width is 400 pixels
//       fixed {
//         ...GatsbyImageSharpFixed
//       }
//     }
//   }
// }
// `)

export default IndexPage;
