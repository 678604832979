import * as React from "react"

function SvgFramer(props) {
  return (
    <svg viewBox="0 0 13 15" {...props}>
      <path d="M0 14V1l6.5 6.5L13 1v13l-3.25-3.25L6.5 14l-3.25-3.25z" />
    </svg>
  )
}

export default SvgFramer