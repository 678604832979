/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { connect, useDispatch } from 'react-redux';
// import SliderHomeReducer from './reducer';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import LinkAnim from '../../UI/links/links';

import { ButtonMain } from '../../UI/buttons/main.tsx';
import { ButtonOut } from '../../UI/buttons/outlink.tsx';
import * as AnimMotion from './animation';
import SvgFramer from '../../UI/SVG/framer';
import SvgGraphql from '../../UI/SVG/graphql';
import SvgGatsby from '../../UI/SVG/gatsby';
import SvgReact from '../../UI/SVG/react';
import SvgNext from '../../UI/SVG/next';
import SvgApollo from '../../UI/SVG/apollo';

const animationText = {
    initial: { opacity: 0, x: 0, y: 20 },
    enter: {
        opacity: 1,
        x: 0,
        y: 0,
        transition: {
            type: "spring", 
            damping: 20, 
            stiffness:50,
        },
    },
    exit: {
        opacity: 0,
        x: 0,
        y: 10,
        transition: {
            type: 'tween',
            duration: 0.35,
        },
    },
}

const animNumber = {
    initial: { opacity: 0, y: 30 },
    enter: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            damping: 7,
            stiffness: 50,
            mass: 1,
            duration: 0.2,
        },
    },
    exit: {
        opacity: 0, y: -20,
        transition: {
            type: 'tween',
            duration: 0.50,
            when: 'beforeChildren',
        },
    },
}


const Prez = ({ slideNbr }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        slideNbr < 1 ? dispatch({ type: 'SetSliderIndexation', index: 5 }) : null;
        slideNbr > 5 ? dispatch({ type: 'SetSliderIndexation', index: 1 }) : null;
    }, [slideNbr]);

    return (
        <div className="slider_home">
            <div className="slider_wrapper items-center justify-center">
                <nav className="slider_nav w-48 fixed left-24 z-30 pointer-events-none flex flex-col items-center justify-center">
                <Previous slideNbr={slideNbr} />
                <Next slideNbr={slideNbr} /></nav>
                <div className="absolute h-full w-full max-w-screen-xl flex items-center justify-between mt-20">
                    <AnimatePresence>
                        <motion.div
                            key={slideNbr}
                            variants={animationText}
                            initial="initial"
                            animate="enter"
                            exit="exit"
                            className="absolute flex flex-col items-start justify-between w-2/3"
                        >
                            {slideNbr === 1 ? <SlideDev /> : null}
                            {slideNbr === 2 ? <SlidePerf /> : null}
                            {slideNbr === 3 ? <SlideAnim /> : null}
                            {slideNbr === 4 ? <SlideDesign /> : null}
                            {slideNbr === 5 ? <SlideCMS /> : null}
                        </motion.div>

                    </AnimatePresence>
                </div>
                <div className="absolute flex flex-row gap-0 bottom-0 mb-10 right-24">
                    <AnimatePresence>
                        <motion.div
                            key={slideNbr}
                            initial="initial"
                            animate="enter"
                            exit="exit"
                            variants={animNumber}
                            className="relative text-white tracking-none font-radiance-bold text-6xl flex flex-row items-end justify-end"
                        >
                            {slideNbr === 1 && <div className="absolute flex flex-row justify-end items-end gap-0"><div>0</div><motion.div variants={animNumber}>1</motion.div></div>}
                            {slideNbr === 2 && <div className="absolute flex flex-row justify-end items-end gap-0"><div>0</div><motion.div variants={animNumber}>2</motion.div></div>}
                            {slideNbr === 3 && <div className="absolute flex flex-row justify-end items-end gap-0"><div>0</div><motion.div variants={animNumber}>3</motion.div></div>}
                            {slideNbr === 4 && <div className="absolute flex flex-row justify-end items-end gap-0"><div>0</div><motion.div variants={animNumber}>4</motion.div></div>}
                            {slideNbr === 5 && <div className="absolute flex flex-row justify-end items-end gap-0"><div>0</div><motion.div variants={animNumber}>5</motion.div></div>}
                        </motion.div>
                    </AnimatePresence>
                    <div className="font-radiance-bold mb-2 text-3xl text-gradient-teal">/05</div>
                </div>
            </div>
        </div>
    );
};

const Previous = () => {
    const dispatch = useDispatch();

    const svgvariants = {
        hover: {
            scale: 1.15,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 60,
                mass: 0.7,
            },
        },
        tap: {
            scale: 1.15,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
                duration: 0.25,
            },
        },
    }

    const iconVariants = {
        initial:
            { pathLength: 0, pathOffset: 0 },
        tap: {
            pathLength: 1,
            pathOffset: 1,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
                duration: 0.25,
            },
        },
        hover: {
            pathLength: 1,
            pathOffset: 1,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
            },
        },
    }

    const iconColored = {
        hover: {
            stroke: "#7632cd",
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
                duration: 0.25,
            },
        },
        tap: {
            stroke: "#7632cd",
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
                duration: 0.25,
            },
        }
    }

    const arrow = {
        tap: {
            x: -5,
            scale: 1.1,
            fill: "#FFF"
        },
        hover:
        {
            fill: "#FFF",
        }
    }

    const shine = {
        initial:
            { opacity: 0, scale: 1 },
        hover: {
            opacity: 0.5,
            scale: 1.6,
            duration: 0.25,
        },
        tap: {
            opacity: 0.5,
            scale: 1.6,
            duration: 0.25,
        }
    }


    return (
        <>
            <motion.div initial="initial" whileTap="tap" whileHover="hover" className="Previous_slide pointer-events-auto" onClick={() => dispatch({ type: 'DecrementIndex' })}>
                <motion.svg version="1.1" variants={svgvariants} id="Layer_1" x="0px" y="0px" viewBox="0 0 100 100">
                    <motion.path strokeWidth="4" fill="transparent" variants={iconColored} stroke="#2d2f90" d="M50,94L6,50L50,6l44,44L50,94z" />
                    <motion.path stroke="#b315d6" fill="transparent" variants={iconVariants} strokeWidth="4" d="M50,94L6,50L50,6l44,44L50,94z" />
                    <motion.path fill="#3c25ba" className="arrow" variants={arrow} d="M41.3,49.2l8.8-8.8c0.4-0.4,1.1-0.4,1.5,0l1,1c0.4,0.4,0.4,1.1,0,1.5l-7,7l7,7c0.4,0.4,0.4,1.1,0,1.5l-1,1c-0.4,0.4-1.1,0.4-1.5,0l-8.8-8.8C40.9,50.3,40.9,49.7,41.3,49.2z" />
                </motion.svg>
                <motion.div className="gradient-blur" variants={shine}></motion.div>
            </motion.div>
        </>
    )
}

const Next = () => {
    const dispatch = useDispatch();

    const svgvariants = {
        hover: {
            scale: 1.1,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 60,
                mass: 0.4,
            },
        },
        tap: {
            scale: 1.15,
            transition: {
                type: 'spring',
                damping: 4,
                stiffness: 60,
                mass: 0.4,
                duration: 0.25,
            },
        },
    }

    const iconVariants = {
        initial:
            { pathLength: 0, pathOffset: 0},
        tap: {
            pathLength: 1,
            pathOffset: 1,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
                duration: 0.25,
            },
        },
        hover: {
            pathLength: 1,
            pathOffset: 1,
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
                duration: 0.25,
            },
        },
    }
    const arrow = {
        hover:
        {
            fill: "#FFF",
        },
        tap: {
            x: 5,
            scale: 1.1,
        }
    }
    const iconColored = {
        hover: {
            stroke: "#7632cd",
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
                duration: 0.25,
            },
        },
        tap: {
            stroke: "#7632cd",
            transition: {
                type: 'spring',
                damping: 10,
                stiffness: 20,
                mass: 0.5,
                duration: 0.25,
            },
        }
    }
    const shine = {
        initial:
            { opacity: 0, scale: 1 },
        hover: {
            opacity: 0.5,
            scale: 1.6,
            duration: 0.25,
        },
        tap: {
            opacity: 0.5,
            scale: 1.6,
            duration: 0.25,
        }
    }


    return (
        <>
            <motion.div initial="initial" whileTap="tap" whileHover="hover" className="Next_slide pointer-events-auto" onClick={() => dispatch({ type: 'IncrementIndex' })}>
                <motion.svg version="1.1" variants={svgvariants} id="Layer_1" x="0px" y="0px" viewBox="0 0 100 100">
                    <motion.path strokeWidth="4" fill="transparent" variants={iconColored} stroke="#2d2f90" d="M50,94L6,50L50,6l44,44L50,94z" />
                    <motion.path stroke="#b315d6" fill="transparent" variants={iconVariants} strokeWidth="4" d="M50,94L6,50L50,6l44,44L50,94z" />
                    <motion.path fill="#3c25ba" className="arrow" variants={arrow} d="M55.7,49.2l-8.8-8.8c-0.4-0.4-1.1-0.4-1.5,0l-1,1c-0.4,0.4-0.4,1.1,0,1.5l7,7l-7,7c-0.4,0.4-0.4,1.1,0,1.5l1,1c0.4,0.4,1.1,0.4,1.5,0l8.8-8.8C56.1,50.3,56.1,49.7,55.7,49.2z" />
                </motion.svg>
                <motion.div className="gradient-blur" variants={shine}></motion.div>
            </motion.div>
        </>
    )
}

const SlideDev = () => {

    const { t } = useTranslation(['slider']);

    const phrase1 = Array.from(t('slider~welcome'));
    const phrase2 = Array.from(t("slider~I'm a freelance"));
    const phrase3 = Array.from(t('slider~react developer'));
    // const phrase3 = Array.from("I develop blazing-fast websites/PWA on the latest frameworks the web as to offer.");

    return (
        <>
            <motion.div
                variants={AnimMotion.headlinetext}
                className="inline-flex gap-2"
            >
                {phrase1.map((letter, index) => (
                    <motion.h2
                        key={index}
                        width={"auto"} // Set the width to the width of the letter
                        height={10} // Set the height to the height of the text
                        background={""}
                        className="text-lg select-none uppercase font-radiance text-white"
                        style={{ position: "relative" }} // Position elements
                        variants={animationText}
                    >
                        {letter === " " ? "\u00A0" : letter}
                    </motion.h2>
                ))}
            </motion.div>
            <div className="flex flex-col -gap-5 leading-none mt-8">
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex "
                >
                    {phrase2.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex"
                >
                    {phrase3.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
            </div>
            <div className="paragraphe-slide">
                <motion.div variants={AnimMotion.subtext}>
                    <p className="text-xl text-gradient font-bold">{t("slider~Hi ! My name is Jeremy Charras, I'm also a webdesigner and animator.")}</p>
                    <p className="text-lg font-light">{t('slider~I do blazing-fast PWA with the latest frameworks the web as to offer.')}</p>
                    <ul className="flex flex-row gap-4 mt-5">
                        <li className="react relative"><LinkAnim href="https://en.reactjs.org/"><SvgReact className="h-4 w-auto fill-current" />React</LinkAnim></li>
                        <li className="text-white relative"><LinkAnim href="https://nextjs.org/"><SvgNext className="h-4 w-auto fill-current" />Next</LinkAnim></li>
                        <li className="gatsby relative"><LinkAnim href="https://www.gatsbyjs.org/"><SvgGatsby className="h-4 w-auto fill-current" />Gatsby</LinkAnim></li>
                        <li className="graphql relative"><LinkAnim href="https://graphql.org/"><SvgGraphql className="h-4 w-auto fill-current" />GraphQL</LinkAnim></li>
                        <li className="framer relative"><LinkAnim href="https://www.framer.com/motion/"><SvgFramer className="h-4 w-auto fill-current" />Framer.Motion</LinkAnim></li>
                        <li className="framer text-indigo-300 relative"><LinkAnim href="https://www.apollographql.com/"><SvgApollo className="h-4 w-auto fill-current" />Apollo</LinkAnim></li>
                    </ul>
                </motion.div>
            </div>
            <div className="flex flex-row gap-10 items-center justify-left">
                <ButtonOut action="mail" icon="mail" className="w-56 h-20 text-white cursor-pointer">{t('slider~Get in touch')}</ButtonOut>
                <ButtonMain href="/projects" icon="projects" color="pink" className="w-56 h-20 text-white">{t('slider~Projects')}</ButtonMain>
            </div>
        </>
    );
};

const SlidePerf = () => {

    const { t } = useTranslation(['slider']);

    const phrase1 = Array.from(t('slider~user first'));
    const phrase2 = Array.from(t('slider~I strive for'));
    const phrase3 = Array.from(t('slider~performance'));

    return (
        <>
            <motion.div
                variants={AnimMotion.headlinetext}
                className="inline-flex gap-2"
            >
                {phrase1.map((letter, index) => (
                    <motion.h2
                        key={index}
                        width={"auto"} // Set the width to the width of the letter
                        height={10} // Set the height to the height of the text
                        background={""}
                        className="text-lg select-none  uppercase font-radiance text-white"
                        style={{ position: "relative" }} // Position elements
                        variants={animationText}
                    >
                        {letter === " " ? "\u00A0" : letter}
                    </motion.h2>
                ))}
            </motion.div>
            <div className="flex flex-col -gap-5 leading-none mt-8">
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex "
                >
                    {phrase2.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex"
                >
                    {phrase3.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
            </div>
            <div className="paragraphe-slide">
                <motion.div variants={AnimMotion.subtext}>
                    <p className="text-xl text-gradient font-bold">{t('slider~Deliver optimized content. For everyone. Even offline.')}<br></br></p>
                    <p className="text-lg font-light">{t('slider~Audit this website and check for yourself !')}</p>
                </motion.div>
            </div>
            <ButtonOut action="audit" icon="audit" className="w-56 h-20 text-white cursor-pointer">{t('slider~Check report')}</ButtonOut>
        </>
    );
};

const SlideAnim = () => {

    const { t } = useTranslation(['slider']);

    const phrase1 = Array.from(t('slider~Animations'));
    const phrase2 = Array.from(t('slider~Giving life'));
    const phrase3 = Array.from(t('slider~to your projects'));

    return (
        <>
            <motion.div
                variants={AnimMotion.headlinetext}
                className="inline-flex gap-2"
            >
                {phrase1.map((letter, index) => (
                    <motion.h2
                        key={index}
                        width={"auto"} // Set the width to the width of the letter
                        height={10} // Set the height to the height of the text
                        background={""}
                        className="text-lg select-none  uppercase font-radiance text-white"
                        style={{ position: "relative" }} // Position elements
                        variants={animationText}
                    >
                        {letter === " " ? "\u00A0" : letter}
                    </motion.h2>
                ))}
            </motion.div>
            <div className="flex flex-col -gap-5 leading-none mt-8">
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex "
                >
                    {phrase2.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex"
                >
                    {phrase3.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
            </div>
            <div className="paragraphe-slide">
                <motion.div variants={AnimMotion.subtext}>
                    <p className="text-xl text-gradient font-bold">{t('slider~I use performants and visually appealing animations.')}</p>
                    <p className="text-lg font-light">{t('slider~Get exciting, memorable and engaging interfaces for your users.')}</p>
                </motion.div>
            </div>
            <ButtonMain href="/" colorClass="BtnViolet" className="w-56 h-20 text-white">{t('slider~Samples')}</ButtonMain>
        </>
    );
};

const SlideDesign = () => {

    const { t } = useTranslation(['slider']);

    const phrase1 = Array.from(t('slider~top SEO'));
    const phrase2 = Array.from(t('slider~Search &'));
    const phrase3 = Array.from(t('slider~be found'));

    return (
        <>
            <motion.div
                variants={AnimMotion.headlinetext}
                className="inline-flex gap-2"
            >
                {phrase1.map((letter, index) => (
                    <motion.h2
                        key={index}
                        width={"auto"} // Set the width to the width of the letter
                        height={10} // Set the height to the height of the text
                        background={""}
                        className="text-lg select-none  uppercase font-radiance text-white"
                        style={{ position: "relative" }} // Position elements
                        variants={animationText}
                    >
                        {letter === " " ? "\u00A0" : letter}
                    </motion.h2>
                ))}
            </motion.div>
            <div className="flex flex-col -gap-5 leading-none mt-8">
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex "
                >
                    {phrase2.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex"
                >
                    {phrase3.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
            </div>
            <div className="paragraphe-slide">
                <motion.div variants={AnimMotion.subtext}>
                    <p className="text-xl text-gradient font-bold">{t("slider~Crush your competition and become one of the first in SE ranking. ")}</p>
                    <p className="text-lg font-light">{t("slider~PWA, lazy load, offline mode, fast performances contribute to your visibility.")}</p>
                </motion.div>
            </div>
            <ButtonMain href="/" colorClass="BtnViolet" className="w-56 h-20 text-white">{t("slider~See how")}</ButtonMain>
        </>
    );
};

const SlideCMS = () => {

    const { t } = useTranslation(['slider']);

    const phrase1 = Array.from(t("slider~tailored"));
    const phrase2 = Array.from(t("slider~React front"));
    const phrase3 = Array.from(t("slider~any CMS"));

    return (
        <>
            <motion.div
                variants={AnimMotion.headlinetext}
                className="inline-flex gap-2"
            >
                {phrase1.map((letter, index) => (
                    <motion.h2
                        key={index}
                        width={"auto"} // Set the width to the width of the letter
                        height={10} // Set the height to the height of the text
                        background={""}
                        className="text-lg select-none  uppercase font-radiance text-white"
                        style={{ position: "relative" }} // Position elements
                        variants={animationText}
                    >
                        {letter === " " ? "\u00A0" : letter}
                    </motion.h2>
                ))}
            </motion.div>
            <div className="flex flex-col -gap-5 leading-none mt-8">
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex "
                >
                    {phrase2.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
                <motion.div
                    variants={AnimMotion.headlinetext}
                    className="inline-flex"
                >
                    {phrase3.map((letter, index) => (
                        <motion.h1
                            key={index}
                            width={"auto"} // Set the width to the width of the letter
                            height={50} // Set the height to the height of the text
                            background={""}
                            className="title-slide"
                            style={{ position: "relative" }} // Position elements
                            variants={animationText}
                        >
                            {letter === " " ? "\u00A0" : letter}
                        </motion.h1>
                    ))}
                </motion.div>
            </div>
            <div className="paragraphe-slide">
                <motion.div variants={AnimMotion.subtext}>
                    <p className="text-xl text-gradient font-bold">{t("slider~Deliver your content with any CMS - with or without database.")}</p>
                    <p className="text-lg font-light">{t("slider~API-based or GiT-based, I use the best for your projects.")}</p>
                </motion.div>
            </div>
            <ButtonMain href="/" colorClass="BtnViolet" className="w-56 h-20 text-white">{t("slider~Learn more")}</ButtonMain>
        </>
    );
};

// eslint-disable-next-line arrow-parens
export default connect(state => ({
    slideNbr: state.SliderHomeReducer.slideNbr,
}), null)(Prez);
