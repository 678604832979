import * as React from "react"

function SvgNext(props) {
    return (
        <svg
            height={25}
            viewBox="0 0 75 65"
            fill="var(--geist-foreground)"
            {...props}
        >
            <path d="M37.59.25l36.95 64H.64l36.95-64z" />
        </svg>
    )
}

export default SvgNext